import React from "react";
import img1 from "../Images/cargo1.jpg";
import img2 from "../Images/cargo2.jpg";
import img3 from "../Images/cargo3.jpg";
import img4 from "../Images/cargo4.jpg";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./CSS/image-slider.css";

import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: img1 },
  { url: img2 },
  { url: img3 },
  { url: img4 },
  // { url: img5 },
  // { url: img6 },
];

const ImgSlider = () => {
  return (
    <div>
      <div style={{ filter: "brightness(45%)" }}>
        <SimpleImageSlider
          height="100vh"
          width="100vw"
          // height={mobileView ? 300 : 550}
          // images={tabView ? mobileImgs : desktopImgs}
          images={images}
          showBullets={true}
          // showNavs={true}
          autoPlay={true}
          loop={true}
          slideDuration={1.5}
          autoPlayDelay={2.5}
        />
      </div>
      <div className="slider-text">
        <p
          style={{
            fontFamily: "helveticaextblk",
            color: "white",

            // marginTop: '-19px',
          }}
          className="slider-title"
        >
          Delivering Commodities<br></br> all around the World
        </p>
        <p
          className="slider-desc"
          style={{
            fontFamily: "poppinslight",
            // marginBottom:"5rem",
            color: "#ebebeb",
          }}
        >
          TSC Global’s trading division specializes in sourcing top-quality
          products made in the USA, connecting businesses worldwide with
          exceptional American-made goods.
        </p>
        {/* <Button
          style={{
            backgroundColor: "transparent",
            border: "2px solid #ebebeb",
            borderRadius: "0px",
            color: "white",
            padding: "10px 30px",
            fontFamily: "poppinsmedium",
          }}
        >
          Learn More
        </Button> */}
        <div>
          <Link to="/services" style={{ textDecoration: "none" }}>
            <a
              target="_blank"
              className="learnmore"
              style={{
                fontFamily: "poppinsbold",
                fontSize: "20px",
                padding: "10px 20px",
                transition: "background-color 0.3s",
              }}
            >
              Learn More
            </a>
          </Link>
        </div>
      </div>
      {/* <h1
        style={{
          fontFamily: "helveticaextblk",
          color: "white",
          marginTop: "-20px",
        }}
      >
        Delivering Projects<br></br> all around the World
      </h1> */}
    </div>
  );
};
export default ImgSlider;
