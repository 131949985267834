import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Complogo from "../Images/Logo-white.png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./CSS/appbar.css";
import { InstallMobile } from "@mui/icons-material";

function ResponsiveAppBar() {
  const [isPhone, setIsPhone] = useState(window.innerWidth < 600);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [height, setHeight] = useState(0);
  const testRef = useRef(null);

  useEffect(() => {
    if (testRef.current) {
      console.log(testRef.current.offsetHeight);
      setHeight(testRef.current.offsetHeight);
    }
  });

  useEffect(() => {
    function handleResize() {
      setIsPhone(window.innerWidth < 600);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div
        // maxWidth
        style={{
          position: "fixed",
          backgroundColor: "transparent",
          backgroundImage:
            "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0, 0, 0,0.8))",
          boxShadow: isMenuOpen ? "none" : "2px 2px 4px rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(3px) ",
          width: "100vw",
        }}
      >
        <div className="toolbar" ref={testRef}>
          <a href="/">
            <img
              src={Complogo}
              alt="Logo"
              style={{ width: isPhone ? "250px" : "330px" }}
            ></img>
          </a>
          {isPhone ? (
            <div className="mobile-menu">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/menu--v1.png"
                alt="menu--v1"
                onClick={toggleMenu}
              />
              {isMenuOpen && (
                <div
                  className="mobile-menu-items"
                  style={{
                    top: height,
                    display: isMenuOpen ? "block" : "none",
                  }}
                >
                  <Link to="/aboutus">About Us</Link>
                  <Link to="/services">Services</Link>
                  <Link to="/contactus">Contact Us</Link>
                </div>
              )}
            </div>
          ) : (
            <div className="menu-wwrapper">
              <Link to="/aboutus">
                {" "}
                <button
                  className="head"
                  variant="text"
                  style={{
                    color: "white",
                    fontFamily: "helveticabold",
                    fontSize: "19px",
                    textTransform: "capitalize",
                    // marginLeft: '45vw',
                    backgroundColor: "transparent",
                    border: "none",

                    whiteSpace: "nowrap",
                  }}
                >
                  About Us
                </button>{" "}
              </Link>
              <Link to="/services">
                {" "}
                <button
                  className="head"
                  variant="text"
                  style={{
                    color: "white",
                    fontFamily: "helveticabold",
                    fontSize: "19px",
                    textTransform: "capitalize",
                    marginLeft: "3rem",
                    // marginLeft: '45vw',
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  Services
                </button>{" "}
              </Link>
              <Link to="/contactus">
                <button
                  className="head"
                  variant="text"
                  style={{
                    color: "white",
                    fontFamily: "helveticabold",
                    fontSize: "19px",
                    textTransform: "capitalize",
                    marginLeft: "3rem",
                    backgroundColor: "transparent",
                    border: "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  Contact Us
                </button>{" "}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default ResponsiveAppBar;
