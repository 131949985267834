import * as React from "react";
import Complogo from "../Images/Logo-white.png";
import ln from "../Images/Icons/linkedin.png";
import tw from "../Images/Icons/twitter.png";
import fb from "../Images/Icons/facebook.png";
import ig from "../Images/Icons/instagram.png";
import Elastec from "../Images/elastec-logo.svg";
import sdvosb from "../Images/SDVOSB.png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./CSS/footer.css";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        marginTop: "-5px",
      }}
      className="footer-wrapper"
    >
      <div style={{}}>
        <img src={Complogo} alt="Logo" style={{ width: "270px" }}></img>
        <p
          style={{
            fontFamily: "poppinsmedium",
            color: "#d7d7d7",
            marginLeft: "3rem",
          }}
        >
          Head Office<br></br> 6701 Democracy Blvd. Ste. 300<br></br> Bethesda,
          MD 20817, United States
          <br />
          <span style={{ fontFamily: "poppinslight" }}>Tel:</span> +1(202)
          516-9871
          <br />
          <span style={{ fontFamily: "poppinslight" }}>E-mail:</span>{" "}
          contactus@tscglobal.co
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginLeft: "3rem",
          }}
        >
          <img src={ln} alt="ln" style={{ width: "35px" }}></img>
          <img src={fb} alt="fb" style={{ width: "35px" }}></img>
          <img src={ig} alt="ag" style={{ width: "35px" }}></img>
          <img src={tw} alt="tw" style={{ width: "35px" }}></img>
        </div>
      </div>
      <div className="about">
        <div
          style={{
            fontFamily: "poppinsbold",
            fontSize: "20px",
            marginTop: "1rem",
          }}
        >
          <Link
            to="/aboutus"
            className="foot"
            style={{
              textDecoration: "none",
              marginBottom: "10rem ",
              marginLeft: "5px",
            }}
          >
            ABOUT US
          </Link>
          <br></br>
          <hr style={{ opacity: "50%" }}></hr>
          <div style={{ height: "10px" }}></div>
          <Link
            className="foot"
            to={"/"}
            style={{ textDecoration: "none", marginLeft: "5px" }}
          >
            HOME
          </Link>
          <br></br>
          <div style={{ height: "20px" }}></div>
          <Link
            className="foot"
            to={"/services"}
            style={{ textDecoration: "none", marginLeft: "5px" }}
          >
            SERVICES
          </Link>
          <br></br>
          <div style={{ height: "20px" }}></div>
          <Link
            to={"/contactus"}
            className="foot"
            style={{ textDecoration: "none", marginLeft: "5px" }}
          >
            CONTACT US
          </Link>
        </div>
      </div>
      <div
        className="services"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <img
          src={Elastec}
          alt="Elastec"
          style={{
            width: "10rem",
          }}
        ></img>
        <a
          href="https://business.defense.gov/Socioeconomic-Programs/SDVOSB/"
          target="_blank"
        >
          <img
            src={sdvosb}
            href="https://business.defense.gov/Socioeconomic-Programs/SDVOSB/"
            alt="Elastec"
            style={{
              width: "6rem",
              marginTop: "2rem",
              marginLeft: "2rem",
            }}
          ></img>
        </a>
      </div>
    </div>
  );
};
export default Footer;
