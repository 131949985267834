import ResponsiveAppBar from '../Components/appbar'
import Body from '../Components/body'
import ImgSlider from '../Components/image-slider'
import Foot from '../Components/footer'
import { useEffect } from 'react'
function Home() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div style={{ position: 'relative' }}>
                <div style={{ zIndex: '2', position: 'absolute' }}>
                    <ResponsiveAppBar />
                </div>
                <div style={{ zIndex: '1' }}>
                    <ImgSlider />
                </div>
            </div>
            <Body />
            <Foot />
        </div>
    )
}

export default Home
