import React, { useState } from "react";
import emailjs from "emailjs-com";
import Elastec from "../Images/elastec-logo.svg";
import img1 from "../Images/cargo1.jpg";
import img3 from "../Images/3.jpg";
import img4 from "../Images/34.jpg";
import Icon1 from "../Images/Icons/ficon1.png";
import Icon2 from "../Images/Icons/ficon2.png";
import Icon3 from "../Images/Icons/ficon3.png";
import Icon4 from "../Images/Icons/ficon4.png";
import Elastecbg from "../Images/oilfactory.jpg";
import Import from "../Images/import.jpg";
import Export from "../Images/export.jpg";
import "../App.css";
import "./CSS/body.css";
import { Link } from "react-router-dom";

function Body() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    const templateParams = {
      from_name: name,
      from_phone: phone,
      from_company: company,
      from_city: city,
      from_email: email,
      comment: comment,
    };

    emailjs
      .send(
        "service_gj460zn",
        "template_jrv0egr",
        templateParams,
        "Nhb4s1FQ01nuKR-7l"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );

    // Reset form
    setName("");
    setPhone("");
    setCompany("");
    setCity("");
    setEmail("");
    setComment("");
  };

  return (
    <div>
      <div
        //  style={{ height: '100vh' }}
        className="my-div"
      >
        <div className="entrepreneur-wrapper">
          <div>
            <p
              style={{
                fontFamily: "helveticabold",
                fontSize: "50px",
                width: "90%",
                margin: "auto",
              }}
            >
              <br></br>
              <span style={{ color: "#00c746" }}>Entrepreneurs</span> in
              Emerging Markets
              <br></br>
              <p
                style={{
                  fontFamily: "poppinsmedium",

                  color: "#505050",
                }}
                className="entrepreneur-text"
              >
                We help exporters gain access to new foreign markets and
                importers to locate sought-after commodities & products.
              </p>
            </p>
          </div>
          <img className="entrepreneur-image" src={img1}></img>
        </div>
      </div>
      <div
        style={{
          padding: "2rem 2rem 5rem 2rem",
        }}
      >
        <h1
          style={{
            fontFamily: "helveticamedium",
            fontSize: "50px",
            color: "#1a1a1a",
            textAlign: "center",
          }}
        >
          Importing & Exporting
        </h1>
        <hr style={{ width: "100px" }}></hr>
        <p
          style={{
            fontFamily: "poppinsmedium",
            fontSize: "20px",
            color: "#505050",
            marginTop: "4rem",
            textAlign: "center",
          }}
        >
          We specialize in providing solutions & services to both exporters and
          importers, connecting our clients with global markets.
        </p>
        <div className="image-container-ie">
          <div className="image-container">
            <img
              className="image-style"
              src={Import}
              alt="My Image"
              style={{
                borderRadius: "20px",
              }}
            />
            <div
              className="image-text"
              // style={{ marginLeft: '-2.5rem' }}
            >
              Imports
            </div>
          </div>

          <div className="image-container">
            <img
              className="image-style"
              src={Export}
              alt="My Image"
              style={{
                borderRadius: "20px",
              }}
            />
            <div
              className="image-text"
              // style={{ marginLeft: '2.5rem' }}
            >
              Exports
            </div>
          </div>
        </div>
      </div>
      <h1
        style={{
          textAlign: "center",
          fontFamily: "helveticamedium",
          fontSize: "50px",
          color: "#1a1a1a",
        }}
      >
        Services we Provide
      </h1>
      <hr style={{ width: "100px", marginTop: "-0.5rem" }}></hr>
      <p
        style={{
          fontSize: "20px",
          fontFamily: "poppinsmedium",
          textAlign: "center",
          color: "#505050",
          //   marginTop: "3rem",
          //   marginBottom: "4rem",
          margin: "3vh 10vw 7rem 10vw",
        }}
      >
        We provide World class facilities for your goods to transport safely and
        in the best possible condition.
      </p>
      <div
        class="icon-grid"
        style={{
          // padding: '0rem 13rem',
          width: "70%",
          margin: "auto",
          paddingTop: "2rem",
          marginBottom: "10rem",
        }}
      >
        <div class="icon">
          <img src={Icon1} alt="Icon 1"></img>
          <h3 class="icon-heading">Supplier Verification</h3>
          <p class="icon-subheading">Due diligence to ensure the credibility</p>
        </div>
        <div class="icon">
          <img src={Icon2} alt="Icon 2"></img>
          <h3 class="icon-heading">Product Sourcing</h3>
          <p class="icon-subheading">Sourcing a wide variety of products</p>
        </div>
        <div class="icon">
          <img src={Icon3} alt="Icon 3"></img>
          <h3 class="icon-heading">Trade Financing</h3>
          <p class="icon-subheading">Trade Financing and Payment Solutions</p>
        </div>
        <div class="icon">
          <img src={Icon4} alt="Icon 4"></img>
          <h3 class="icon-heading">Consultation</h3>
          <p class="icon-subheading">Consultation and Advisory Services</p>
        </div>
      </div>

      <hr style={{ width: "90vw", borderColor: "red", marginTop: "20vh" }}></hr>

      <div style={{ marginTop: "5rem", marginBottom: "4rem" }}>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Poppinsbold",
            color: "#505050",
            margin: "3vh 7vw 4rem 7vw",
          }}
          className="reach-out-heading"
        >
          Reach Out to <span style={{ color: "#00c746" }}>Us.</span>
        </div>
        <div
          className="cform"
          style={{
            margin: "auto",
          }}
        >
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="name"
              style={{
                fontFamily: "helveticamedium",
                fontSize: "17px",
              }}
            >
              Name
              <span
                style={{
                  color: "#00c746",
                  fontFamily: "helveticabold",
                }}
              >
                :
              </span>
            </label>
            <br></br>
            <input
              style={{
                width: "100%",
                fontFamily: "helveticalight",
                marginTop: "0.5rem",
              }}
              className="input"
              type="text"
              id="company"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <br></br>
            <br></br>

            <label
              htmlFor="phone"
              style={{
                fontFamily: "helveticamedium",
                fontSize: "17px",
              }}
            >
              Phone Number (with country code)
              <span
                style={{
                  color: "#00c746",
                  fontFamily: "helveticabold",
                }}
              >
                :
              </span>
            </label>
            <br></br>
            <input
              style={{
                width: "100%",
                fontFamily: "helveticalight",
                marginTop: "0.5rem",
              }}
              className="input"
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <br></br>
            <br></br>

            <label
              htmlFor="copmany"
              style={{
                fontFamily: "helveticamedium",
                fontSize: "17px",
              }}
            >
              Company
              <span
                style={{
                  color: "#00c746",
                  fontFamily: "helveticabold",
                }}
              >
                :
              </span>
            </label>
            <br></br>
            <input
              style={{
                width: "100%",
                fontFamily: "helveticalight",
                marginTop: "0.5rem",
              }}
              className="input"
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
            <br></br>
            <br></br>

            <label
              htmlFor="city"
              style={{
                fontFamily: "helveticamedium",
                fontSize: "17px",
              }}
            >
              Location (City, Country)
              <span
                style={{
                  color: "#00c746",
                  fontFamily: "helveticabold",
                }}
              >
                :
              </span>
            </label>
            <br></br>
            <input
              style={{
                width: "100%",
                fontFamily: "helveticalight",
                marginTop: "0.5rem",
              }}
              className="input"
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <br></br>
            <br></br>

            <label htmlFor="email" style={{ fontFamily: "helveticamedium" }}>
              Email
              <span
                style={{
                  color: "#00c746",
                  fontFamily: "helveticabold",
                }}
              >
                :
              </span>
            </label>
            <br></br>
            <input
              style={{
                width: "100%",
                fontFamily: "helveticalight",
                marginTop: "0.5rem",
              }}
              className="input"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br></br>
            <br></br>
            <label htmlFor="comment" style={{ fontFamily: "helveticamedium" }}>
              Message
              <span
                style={{
                  color: "#00c746",
                  fontFamily: "helveticabold",
                }}
              >
                :
              </span>
            </label>
            <br></br>
            <textarea
              style={{
                width: "100%",
                height: "150px",
                fontFamily: "helveticalight",
                marginTop: "0.5rem",
              }}
              className="input"
              id="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
            <br></br>
            <div style={{ textAlign: "right", marginTop: "1rem" }}>
              <button type="submit" className="sub-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        style={{
          marginTop: "5rem",
        }}
      >
        <div>
          <div>
            <div className="elastec-wrapper">
              <div>
                <p className="elastec-title">Environmental Products</p>
                <p className="elastec-desc">
                  Elastec manufactures an extensive range of environmental
                  products: oil skimmers, containment boom, incinerators,vacuum
                  systems, pumps, power packs and work boats.
                </p>
                <Link
                  to="/elastec"
                  style={{
                    textDecoration: "none",
                    padding: "0rem 10vw 0rem 7vw",
                  }}
                >
                  <a
                    target="_blank"
                    className="elastec"
                    style={{
                      fontFamily: "poppinsbold",
                      textDecoration: "none",
                      padding: "10px 20px",
                      transition: "background-color 0.3s",
                    }}
                  >
                    Explore More
                  </a>
                </Link>
              </div>
              <div>
                <img src={Elastec} alt="Elastec" className="elastec-icon"></img>
              </div>
            </div>
            <img
              src={Elastecbg}
              alt="Background"
              className="elastec-bg"
              style={{
                width: "100%",
                height: "80vh",
                objectFit: "cover",
                filter: "brightness(40%)",
                position: "relative",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
