import React, { useEffect } from "react";
import AppBar from "../Components/appbar";
import Elasteclogo from "../Images/elastec-logo.svg";
import TSC from "../Images/Logo.png";
import Oil from "../Images/oilc.jpg";
import "../Components/CSS/body.css";
import "../Components/CSS/aboutus.css";
import Footer from "../Components/footer";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
function Elastec() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            zIndex: "2",
            position: "absolute",
            marginTop: "-12rem",
          }}
        >
          <AppBar />
        </div>

        <div
          style={{
            zIndex: "1",
            marginBottom: "7rem",
            marginTop: "12rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a href="https://www.elastec.com/" target="_blank">
              <img
                src={TSC}
                href="https://www.elastec.com/"
                alt="Elastec"
                className="tsc-img"
                style={
                  {
                    // marginRight: "10rem",
                    // marginBottom: "13rem",
                    // borderRadius: "10px",
                  }
                }
              ></img>
            </a>
            <div
              className="line"
              style={{
                width: "1px",
                backgroundColor: "black",
              }}
            ></div>
            <a href="https://www.elastec.com/" target="_blank">
              <img
                src={Elasteclogo}
                href="https://www.elastec.com/"
                alt="Elastec"
                className="elastec-img"
                style={{
                  marginLeft: "2rem",
                  // marginTop: '1.5rem',
                }}
              ></img>
            </a>
          </div>
          <div style={{ marginTop: "7rem" }} className="elastec-text-wrapper">
            <h1
              style={{
                fontFamily: "poppinsbold",
                color: "#606060",
                fontSize: "40px",
              }}
            >
              <span style={{ color: "#009ec2" }}>Innovative</span> Environmental
              Products
            </h1>
            <p
              style={{
                fontFamily: "poppinsmedium",
                fontSize: "20px",
                color: "#404040",
                textAlign: "left",
              }}
            >
              Elastec manufactures an extensive range of environmental products:
              oil skimmers, containment boom, incinerators, vacuum systems,
              pumps, power packs and work boats.
            </p>
            <p
              style={{
                textAlign: "left",
                fontFamily: "poppinslight",
                fontSize: "18px",
              }}
            >
              Elastec is the largest manufacturer of oil spill cleanup and
              surface water pollution equipment in the world. With headquarters
              in Carmi, Illinois, U.S.A. and three manufacturing facilities in
              southern Illinois and Cocoa, Florida, we are a global leader in
              the distribution of oil skimmers, oil boom, fire boom, dispersant
              application systems, work boats, vacuum systems, portable
              incinerators, turbidity curtains, and custom containment boom for
              floating trash, debris and aquatic weed control. For fifty years,
              our equipment has been operating in 155 countries.
              <br />
              <br />
              We share a passion for keeping our waterways, rivers and oceans
              clean with a network of partners around the globe. From the
              Deepwater Horizon incident in the Gulf of Mexico to the first
              flush from stormwater runoff in Mumbai - surface water pollution -
              it all flows downstream into our waterways and oceans. Our mission
              is to develop solutions to clean up contaminants - oil spills,
              floating trash, debris, silt, sediment, drugs, aquatic weeds and
              F.O.G. (fats, oils and grease) - to keep our world clean.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "7vh",
            }}
          >
            <Link to="/contactus" style={{ textDecoration: "none" }}>
              <a
                target="_blank"
                className="elastecweb"
                style={{
                  fontFamily: "poppinsbold",
                  textDecoration: "none",
                  padding: "10px 80px",
                  transition: "background-color 0.3s",
                }}
              >
                Contact Us
              </a>
            </Link>
          </div>
          <div style={{ marginTop: "10vh" }}>
            <img
              src={Oil}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "70%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            ></img>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Elastec;
