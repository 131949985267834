import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import AppBar from "../Components/appbar";
import Footer from "../Components/footer";
import "../Components/CSS/body.css";
import "../Components/CSS/aboutus.css";

function Contactus() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    const templateParams = {
      from_name: name,
      from_phone: phone,
      from_company: company,
      from_city: city,
      from_email: email,
      comment: comment,
    };

    emailjs
      .send(
        "service_gj460zn",
        "template_jrv0egr",
        templateParams,
        "Nhb4s1FQ01nuKR-7l"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );

    // Reset form
    setName("");
    setPhone("");
    setCompany("");
    setCity("");
    setEmail("");
    setComment("");
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            zIndex: "2",
            position: "absolute",
            marginTop: "-10rem",
          }}
        >
          <AppBar />
        </div>

        <div>
          <div style={{ marginTop: "10rem", marginBottom: "10rem" }}>
            <div
              className="reach-out-heading"
              style={{
                textAlign: "center",
                fontFamily: "Poppinsbold",
                color: "#505050",
              }}
            >
              Reach Out to <span style={{ color: "#00c746" }}>Us.</span>
            </div>
            <div
              className="cform"
              style={{
                margin: "auto",
                marginTop: "4rem",
              }}
            >
              <form onSubmit={handleSubmit}>
                <label
                  htmlFor="name"
                  style={{
                    fontFamily: "helveticamedium",
                    fontSize: "16px",
                  }}
                >
                  Name
                  <span
                    style={{
                      color: "#00c746",
                      fontFamily: "helveticabold",
                    }}
                  >
                    :
                  </span>
                </label>
                <br></br>
                <input
                  style={{
                    width: "100%",
                    fontFamily: "helveticalight",
                    marginTop: "0.5rem",
                  }}
                  className="input"
                  type="text"
                  id="company"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <br></br>
                <br></br>

                <label
                  htmlFor="phone"
                  style={{
                    fontFamily: "helveticamedium",
                    fontSize: "16px",
                  }}
                >
                  Phone Number (with country code)
                  <span
                    style={{
                      color: "#00c746",
                      fontFamily: "helveticabold",
                    }}
                  >
                    :
                  </span>
                </label>
                <br></br>
                <input
                  style={{
                    width: "100%",
                    fontFamily: "helveticalight",
                    marginTop: "0.5rem",
                  }}
                  className="input"
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <br></br>
                <br></br>

                <label
                  htmlFor="copmany"
                  style={{
                    fontFamily: "helveticamedium",
                    fontSize: "16px",
                  }}
                >
                  Company
                  <span
                    style={{
                      color: "#00c746",
                      fontFamily: "helveticabold",
                    }}
                  >
                    :
                  </span>
                </label>
                <br></br>
                <input
                  style={{
                    width: "100%",
                    fontFamily: "helveticalight",
                    marginTop: "0.5rem",
                  }}
                  className="input"
                  type="text"
                  id="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
                <br></br>
                <br></br>

                <label
                  htmlFor="city"
                  style={{
                    fontFamily: "helveticamedium",
                    fontSize: "16px",
                  }}
                >
                  Location (City, Country)
                  <span
                    style={{
                      color: "#00c746",
                      fontFamily: "helveticabold",
                    }}
                  >
                    :
                  </span>
                </label>
                <br></br>
                <input
                  style={{
                    width: "100%",
                    fontFamily: "helveticalight",
                    marginTop: "0.5rem",
                  }}
                  className="input"
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                <br></br>
                <br></br>

                <label
                  htmlFor="email"
                  style={{ fontFamily: "helveticamedium" }}
                >
                  Email
                  <span
                    style={{
                      color: "#00c746",
                      fontFamily: "helveticabold",
                    }}
                  >
                    :
                  </span>
                </label>
                <br></br>
                <input
                  style={{
                    width: "100%",
                    fontFamily: "helveticalight",
                    marginTop: "0.5rem",
                  }}
                  className="input"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <br></br>
                <br></br>
                <label
                  htmlFor="comment"
                  style={{ fontFamily: "helveticamedium" }}
                >
                  Message
                  <span
                    style={{
                      color: "#00c746",
                      fontFamily: "helveticabold",
                    }}
                  >
                    :
                  </span>
                </label>
                <br></br>
                <textarea
                  style={{
                    width: "100%",
                    height: "150px",
                    fontFamily: "helveticalight",
                    marginTop: "0.5rem",
                  }}
                  className="input"
                  id="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
                <br></br>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "1rem",
                  }}
                >
                  <button type="submit" className="sub-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Contactus;
