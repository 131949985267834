import React, { useEffect } from "react";
import AppBar from "../Components/appbar";
import bg from "../Images/cargo1.jpg";
import Img1 from "../Images/about-us.jpg";
import Img2 from "../Images/whyus.png";
import "../Components/CSS/aboutus.css";
import Footer from "../Components/footer";
function About() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            zIndex: "2",
            position: "absolute",
          }}
        >
          <AppBar />
        </div>

        <div
          style={{
            zIndex: "1",
            marginBottom: "10rem",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "500px",
            }}
          >
            <img
              src={bg}
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "brightness(40%)",
                // marginTop: '5.3rem',
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
                fontSize: "60px",
                fontFamily: "helveticabold",
              }}
            >
              About TSC Global
            </div>
          </div>

          {/* <p
            style={{
              marginTop: "10rem",
              fontSize: "50px",
              fontFamily: "helveticabold",
              textAlign: "center",
            }}
          >
            About{" "}
            <span
              style={{
                marginTop: "10rem",
                fontSize: "50px",
                fontFamily: "helveticabold",
                textAlign: "center",
                color: "#00c746",
              }}
            >
              Us
            </span>
          </p> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
            className="service-section-wrapper"
          >
            <div>
              <p
                style={{
                  fontFamily: "helveticabold",
                  // textAlign: 'center',
                  fontSize: "35px",
                }}
                className="aboutus-heading"
              >
                Why Choose{" "}
                <span
                  style={{
                    marginTop: "10rem",
                    fontSize: "37px",
                    fontFamily: "helveticabold",
                    textAlign: "center",
                    color: "#00c746",
                  }}
                >
                  Us
                </span>
              </p>
              <p
                style={{
                  fontFamily: "poppinsregular",
                  color: "#707070",
                  fontSize: "18px",
                  lineHeight: 1.35,
                  textAlign: "left",
                }}
                className="aboutus-text"
              >
                At TSC Global, we offer competitive advantages that make us
                stand out in the industry. Here's why you should choose us:
                <ul>
                  <li>
                    <span
                      style={{
                        color: "#00c746",
                        fontFamily: "poppinsbold",
                      }}
                    >
                      Customized Solutions:
                    </span>{" "}
                    <span>
                      We provide exceptional and tailored solutions for both
                      exporters and importers, understanding the unique needs of
                      each client.
                    </span>
                  </li>
                  <br></br>
                  <li>
                    <span
                      style={{
                        color: "#00c746",
                        fontFamily: "poppinsbold",
                      }}
                    >
                      Global Reach:
                    </span>{" "}
                    <span>
                      With offices in North America, the Middle East, and
                      Africa, and strong partnerships worldwide, we have a
                      global network of producers, suppliers, and investors.
                    </span>
                  </li>
                  <br></br>
                  <li>
                    <span
                      style={{
                        color: "#00c746",
                        fontFamily: "poppinsbold",
                      }}
                    >
                      Market Visibility and Business Intelligence:
                    </span>{" "}
                    <span>
                      Our experienced traders offer market visibility and
                      valuable business intelligence, keeping you informed and
                      empowering informed decision-making.
                    </span>
                  </li>
                  <br></br>
                  <li>
                    <span
                      style={{
                        color: "#00c746",
                        fontFamily: "poppinsbold",
                      }}
                    >
                      Exceptional Trading Services:
                    </span>{" "}
                    <span>
                      Our highly qualified traders navigate complex market
                      dynamics, ensuring optimal results and customer
                      satisfaction.
                    </span>
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <img
                src={Img2}
                alt="Img1"
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                className="about-img"
              ></img>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3rem",
              alignItems: "center",
            }}
            className="service-section-wrapper"
          >
            <div>
              <p
                style={{
                  fontFamily: "helveticabold",
                  // textAlign: 'center',
                  fontSize: "35px",
                }}
                className="aboutus-heading"
              >
                About{" "}
                <span
                  style={{
                    marginTop: "10rem",
                    fontSize: "37px",
                    fontFamily: "helveticabold",
                    textAlign: "center",
                    color: "#00c746",
                  }}
                >
                  Us
                </span>
              </p>
              <p
                style={{
                  fontFamily: "poppinsregular",
                  color: "#707070",
                  fontSize: "18px",
                  lineHeight: 1.35,
                  textAlign: "left",
                }}
                className="aboutus-text"
              >
                TSC Global's trading division is dedicated to procuring
                high-quality products manufactured in the United States,
                facilitating global connections between businesses and
                exceptional American-made goods. Leveraging our extensive
                network and industry knowledge, we offer efficient and
                customized sourcing solutions.{" "}
                <span style={{ fontFamily: "poppinssemibold" }}>
                  Our focus lies in sourcing top-tier products from the USA,
                  ensuring that businesses worldwide have access to outstanding
                  goods.
                </span>{" "}
                <br />
                <br />
                Through our specialized expertise, we provide personalized
                assistance, ensuring that the sourcing process is streamlined
                and effective. TSC Global's trading division is committed to
                enabling seamless trade relationships and helping businesses
                discover the best products the USA has to offer.
              </p>
            </div>
            <div>
              <img
                src={Img1}
                alt="Img1"
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                className="about-img"
              ></img>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default About;
