import React, { useEffect } from 'react'
import AppBar from '../Components/appbar'
import bg from '../Images/cargo1.jpg'
import Img1 from '../Images/ps.jpg'
import Img2 from '../Images/sv.jpg'
import Img3 from '../Images/tf.jpg'
import Img4 from '../Images/co.jpg'
import '../Components/CSS/aboutus.css'
import Footer from '../Components/footer'
function About() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div>
                <div
                    style={{
                        zIndex: '2',
                        position: 'absolute',
                    }}
                >
                    <AppBar />
                </div>

                <div>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '400px',
                        }}
                    >
                        <img
                            src={bg}
                            alt='Background'
                            style={{
                                width: '100%',
                                height: '500px',
                                objectFit: 'cover',
                                filter: 'brightness(40%)',
                                // marginTop: '5.3rem',
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50%, 40%)',
                                textAlign: 'center',
                                color: 'white',
                                fontSize: '60px',
                                fontFamily: 'helveticabold',
                            }}
                            className='service-heading'
                        >
                            Services We Provide
                        </div>
                    </div>

                    <div
                        className='service-section-wrapper'
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '5rem',
                        }}
                    >
                        <div className='service-text-wrapper'>
                            <p
                                style={{
                                    fontFamily: 'helveticabold',
                                    fontSize: '37px',
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '90px',
                                        fontFamily: 'helveticabold',
                                        textAlign: 'center',
                                        color: '#505050',
                                        lineHeight: '37px',
                                    }}
                                >
                                    1.
                                </span>{' '}
                                Product Sourcing
                            </p>
                            <p
                                style={{
                                    fontFamily: 'poppinsregular',
                                    color: '#757575',
                                    fontSize: '20px',
                                    lineHeight: 1.35,
                                    // textAlign: 'justify',
                                }}
                            >
                                We specialize in sourcing a wide variety of
                                products made in the USA, connecting you with
                                reliable suppliers and manufacturers. Our
                                extensive network allows us to find the specific
                                products you require, ensuring top quality and
                                compliance with your specifications.
                            </p>
                        </div>
                        <img
                            src={Img1}
                            alt='Img1'
                            className='service-img-r'
                            // style={{ marginTop: '5rem' }}
                        ></img>
                    </div>

                    <div
                        className='service-section-wrapper-l'
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <img
                                src={Img2}
                                alt='Img1'
                                // style={{ width: '75vh', marginLeft: '40px' }}
                                className='service-img-l'
                            ></img>
                        </div>
                        <div className='service-text-wrapper'>
                            <p
                                style={{
                                    fontFamily: 'helveticabold',
                                    fontSize: '37px',
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '90px',
                                        fontFamily: 'helveticabold',
                                        textAlign: 'center',
                                        color: '#505050',
                                        lineHeight: '37px',
                                    }}
                                >
                                    2.
                                </span>{' '}
                                Supplier Verification and Due Diligence
                            </p>
                            <p
                                style={{
                                    fontFamily: 'poppinsregular',
                                    color: '#757575',

                                    fontSize: '20px',
                                    lineHeight: 1.35,
                                    // textAlign: 'justify',
                                }}
                            >
                                We conduct thorough supplier verification and
                                due diligence to ensure the credibility and
                                reliability of potential partners. Our
                                meticulous process includes assessing their
                                production capabilities, quality control
                                measures, certifications, and compliance with
                                industry standards.
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        className='service-section-wrapper'
                    >
                        <div className='service-text-wrapper'>
                            <p
                                style={{
                                    fontFamily: 'helveticabold',

                                    fontSize: '37px',
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '90px',
                                        fontFamily: 'helveticabold',
                                        textAlign: 'center',
                                        color: '#505050',
                                        lineHeight: '37px',
                                    }}
                                >
                                    3.
                                </span>{' '}
                                Trade Financing and Payment Solutions
                            </p>
                            <p
                                style={{
                                    fontFamily: 'poppinsregular',
                                    color: '#757575',
                                    fontSize: '20px',
                                    lineHeight: 1.35,
                                    // textAlign: 'justify',
                                }}
                            >
                                We provide trade financing options and secure
                                payment solutions to facilitate smooth
                                transactions. Our services include letters of
                                credit, escrow services, and other financial
                                instruments to ensure secure and transparent
                                payment processes.
                            </p>
                        </div>
                        <div>
                            <img
                                src={Img3}
                                alt='Img1'
                                className='service-img-r'
                            ></img>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '5rem',
                        }}
                        className='service-section-wrapper-l'
                    >
                        <div>
                            <img
                                src={Img4}
                                alt='Img1'
                                className='service-img-l'
                            ></img>
                        </div>
                        <div className='service-text-wrapper'>
                            <p
                                style={{
                                    fontFamily: 'helveticabold',

                                    fontSize: '37px',
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '90px',
                                        fontFamily: 'helveticabold',
                                        textAlign: 'center',
                                        color: '#505050',
                                        lineHeight: '37px',
                                    }}
                                >
                                    4.
                                </span>{' '}
                                Consultation and Advisory Services
                            </p>
                            <p
                                style={{
                                    fontFamily: 'poppinsregular',
                                    color: '#757575',

                                    fontSize: '20px',
                                    lineHeight: 1.35,
                                    // textAlign: 'justify',
                                }}
                            >
                                Our team of trade experts provides consultation
                                and advisory services to guide you through the
                                complexities of international trade. We offer
                                strategic advice, regulatory compliance support,
                                and assistance with trade negotiations to help
                                you achieve your business objectives.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default About
